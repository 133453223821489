export default {
  env: 'development',
  api: '/api',
  zendesk: {
    subdomain: 'assetdedication1492475560'
  },
  roles: {
    ALL: [ 'AD Administrator', 'AD Employee', 'Advisor', 'Operations' ],
    AD: [ 'AD Administrator', 'AD Employee' ]
  },
  statuses: [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
    { label: 'All', value: 'all' }
  ],
  permissions: {
    users: [ 'list', 'show', 'new', 'edit', 'activate', 'impersonate', 'role', 'Section - Custodian info (AD only)', 'Section - Access' ],
    companies: [ 'list', 'show', 'new', 'edit', 'Section - Onboard', 'Section - Partners', 'Section - AD only', 'user settings' ],
    models: [ 'list', 'show', 'new', 'edit', 'activate', 'global', 'edit global', 'view global weights', 'assign', 'Section - Onboard', 'Section - Partners', 'Section - AD only', 'download pdf' ],
    households: [ 'list', 'show', 'new', 'edit', 'activate', 'ad only', 'split' ],
    accounts: [ 'list', 'show', 'new', 'edit', 'activate', 'manual account', 'new tamarac accounts', 'convert to uc', 'convert to admin', 'close' ],
    clients: [ 'list', 'show', 'new', 'edit', 'activate', 'delete' ],
    roles: [ 'list', 'show', 'new', 'edit', 'activate' ],
    alerts: [ 'list' ],
    tamarac: [ 'list' ],
    implement: [ 'list' ],
    proposals: [ 'list', 'show', 'new', 'edit', 'versions' ],
    settings: [ 'visible' ],
    internal: [ 'visible' ],
    'action button': [ 'visible' ],
    'service teams': [ 'list', 'new', 'edit' ],
    'critical-paths': [ 'list', 'show', 'new', 'edit', 'versions', 'documents', 'documents actions', 'ad only', 'monitoring' ],
    'billing-definitions': [ 'list', 'new' ],
    'billing-groups': [ 'list', 'new', 'billing group adjustments' ],
    'automated-investing': [ 'list', 'edit' ],
    'auto-sweep': [ 'list', 'edit' ],
    'cash-reserves': [ 'list', 'new', 'edit', 'delete' ],
    'custodial requests': [ 'list', 'new', 'edit', 'paperwork admin' ],
    'custodial forms': [ 'list', 'new', 'edit' ],
    'tamarac uploads': [ 'list' ],
    'proposal inventory': [ 'list', 'edit' ],
    'trading requests': [ 'list', 'view', 'edit', 'modify' ],
    watchers: [ 'list', 'new', 'edit' ],
    workers: [ 'new' ],
    monitor: [ 'drift', 'unassigned securities', 'alerts', 'add cash management', 'drift summary', 'drift tab' ],
    leads: [ 'new' ],
    trading: [ 'visible' ],
    rebalancing: [ 'run' ],
    'tamarac imports': [ 'list', 'new', 'edit', 'unified reports' ],
    'security restrictions': [ 'delete external account restrictions' ],
    banners: [ 'list', 'new', 'edit' ],
    'educational materials': [ 'list', 'new', 'edit' ],
    'adv reports': [ 'list' ],
    'billing fee files': [ 'list' ],
    'income builders': [ 'list', 'edit' ]
  }
}
