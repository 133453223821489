import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FilteredMultiSelect from 'react-filtered-multiselect'

export default class MultiSelect extends Component {
  static propTypes = {
    selectedOptions: PropTypes.array,
    loadOptions: PropTypes.func,
    onChange: PropTypes.func,
    descriptions: PropTypes.object,
    hideFilter: PropTypes.bool
  }

  constructor (props) {
    super(props)
    this.state = {
      loaded: false,
      selectedOptions: props.selectedOptions || []
    }
  }

  componentDidMount () {
    this.reload()
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  componentWillReceiveProps (nextProps) {
    setImmediate(() => this.setState({ selectedOptions: nextProps.selectedOptions || [] }))
  }

  reload = () => {
    const loadOptions = this.props.loadOptions
    loadOptions().then(options => {
      this._isMounted && this.setState({ options, loaded: true })
    })
  }

  handleChange = () => {
    setImmediate(() => {
      const selectedOptions = this.state.selectedOptions
      const onChange = this.props.onChange
      onChange && onChange(selectedOptions)
    })
  }

  handleDeselect = deselectedOptions => {
    const selectedOptions = this.state.selectedOptions.slice()
    deselectedOptions.forEach(option => {
      selectedOptions.splice(selectedOptions.indexOf(option.value), 1)
    })
    this.setState({ selectedOptions }, this.handleChange)
  }

  handleSelect = selectedOptions => {
    selectedOptions.sort((a, b) => a.id - b.id)
    this.setState({ selectedOptions }, this.handleChange)
  }

  render () {
    const { selectedOptions, options, loaded } = this.state

    if (!loaded) {
      return null
    }

    const filteredOptions = options.options.filter(o => selectedOptions.indexOf(o.value) !== -1)

    return (
      <div className="row">
        <div className="col-md-6">
          {this.props.descriptions && (
            <p>{this.props.descriptions.options}</p>
          )}
          <FilteredMultiSelect
            buttonText="Add"
            classNames={{
              filter: 'form-control',
              select: 'form-control',
              button: 'btn btn-round btn-block btn-info waves-effect waves-light',
              buttonActive: 'btn btn btn-block btn-primary'
            }}
            onChange={this.handleSelect}
            options={options.options}
            selectedOptions={selectedOptions.map(o => ({ value: o }))}
            textProp="label"
            valueProp="value"
            showFilter={!this.props.hideFilter}
          />
        </div>
        <div className="col-md-6">
          {this.props.descriptions && (
            <p>{this.props.descriptions.selected}</p>
          )}
          <FilteredMultiSelect
            buttonText="Remove"
            classNames={{
              filter: 'form-control',
              select: 'form-control',
              button: 'btn btn-round btn-block btn-info waves-effect waves-light',
              buttonActive: 'btn btn btn-block btn-danger'
            }}
            onChange={this.handleDeselect}
            options={filteredOptions}
            textProp="label"
            valueProp="value"
            showFilter={!this.props.hideFilter}
          />
        </div>
      </div>
    )
  }
}
